import React from 'react';
import { AppBar, Box, Button, Container, Grid, IconButton, Toolbar, Typography, Card, CardContent } from '@mui/material';import MenuIcon from '@mui/icons-material/Menu';



const fotter = () => {

    return (
<Toolbar>
      <Box sx={{ width: '100%' }}>
        <Grid
          container
          marginY={1}
             marginBottom={4}
          spacing={1}
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          sx={{ mx: 'auto' }}
        >
          <Grid item xs={12} md="auto" >
            <Typography variant="h8" component="div">
              Softrunner
            </Typography>
          </Grid>
          <Grid item xs={12} md="auto">
            <Typography variant="h8" component="div">
              CVR: 41543221
            </Typography>
          </Grid>
          <Grid item xs={12} md="auto">
            <Typography variant="h8" component="div">
              Phone: 42 26 49 33
            </Typography>
          </Grid>
          <Grid item xs={12} md="auto">
            <Typography variant="h8" component="div">
              Email: mail@softrunner.dk
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Toolbar>
    )
}

export default fotter;


import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { Box, Button, Container, TextField, Typography } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import callBack from '../ui/callback.png';
import SendIcon from '@mui/icons-material/Send';
import { Element } from 'react-scroll';

const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [msgSent, setMsgSent] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await emailjs.send(
                'service_ehojwjs', // Your EmailJS service ID
                'template_95vincj', // Your EmailJS template ID
                {
                    name: name,
                    email: email,
                    message: message,
                },
                'D3SjGCt1T9jIIbQef' // Your EmailJS public key
            );

            setName('');
            setEmail('');
            setMessage('');
            setMsgSent(true);
        } catch (error) {
            console.error('Submission error:', error);
        }
    };

    return (
        <Element name="kontakt">
            <Box
                sx={{
                    minHeight: '100vh',
                    background: `url(${callBack}) no-repeat fixed`,
                    backgroundSize: 'cover',
                    color: 'white',
                    position: 'relative',
                    flexDirection: 'column',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 4,
                    pt: 4,
                    pb: 4,
                }}
            >
                <Typography 
                    variant="h3" 
                    color="white" 
                    textAlign="center" 
                    gutterBottom 
                    sx={{ textShadow: '3px 3px 4px rgba(1, 0, 0, 0.5)' }}
                >
                    Kontakt Softrunner
                </Typography>
                <Container
                    sx={{
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        borderRadius: 2,
                        p: 3,
                        textAlign: 'center',
                        width: '80%',
                        maxWidth: '400px',
                    }}
                >
                    <PhoneIcon sx={{ fontSize: '3rem', mb: 1 }} />
                    <Typography variant="h6">
                        Ring til Softrunner
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        Det er altid gratis at ringe
                    </Typography>
                    <Button
                        variant="contained"
                        color="success"
                        startIcon={<PhoneIcon />}
                        href="tel:+4542666498"
                    >
                        +45 42 66 64 98
                    </Button>
                </Container>
                <Container
                    sx={{
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        borderRadius: 2,
                        p: 3,
                        textAlign: 'center',
                        width: '80%',
                        display: msgSent ? 'flex' : 'block',
                        maxWidth: '400px',
                        minHeight: '440px',
                    }}
                >
                    {msgSent ? (
                        <Box
                            textAlign="center"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                margin: '0 auto',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <SendIcon sx={{ fontSize: '3rem', mb: 1 }} />
                            <Typography variant="h6" gutterBottom>
                                Tak for din besked
                            </Typography>
                        </Box>
                    ) : (
                        <form onSubmit={handleSubmit}>
                            <EmailIcon sx={{ fontSize: '3rem', mb: 1 }} />
                            <Typography variant="h6" gutterBottom>
                                Send Softrunner en email
                            </Typography>
                            <Box
                                component="div"
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 2,
                                }}
                            >
                                <TextField
                                    variant="outlined"
                                    label="Et navn"
                                    fullWidth
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                    InputProps={{
                                        sx: { backgroundColor: 'white' },
                                    }}
                                />
                                <TextField
                                    variant="outlined"
                                    label="Din Email"
                                    fullWidth
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    InputProps={{
                                        sx: { backgroundColor: 'white' },
                                    }}
                                />
                                <TextField
                                    variant="outlined"
                                    label="Din besked: tilføj info om dit problem"
                                    multiline
                                    rows={4}
                                    fullWidth
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    required
                                    InputProps={{
                                        sx: { backgroundColor: 'white' },
                                    }}
                                />
                                <Button
                                    variant="contained"
                                    color="success"
                                    endIcon={<SendIcon />}
                                    type="submit"
                                >
                                    Send
                                </Button>
                            </Box>
                        </form>
                    )}
                </Container>
            </Box>
        </Element>
    );
};

export default Contact;


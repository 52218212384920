import React from 'react';
import WelcomeSite from './compontes/WellSite';
import Services from './compontes/Servises';
import Contact from './compontes/Kontakt';
import HomeBar from './compontes/homebar';
import Om from './compontes/om';
import Fotter from './compontes/fotter';
//create pickoOs
import PickoOs from './compontes/pickoOs.js';

const App = () => {
  return (
    <div>
      <HomeBar />
        <WelcomeSite />
        <Services />
        <Contact />
        <Om />
          <PickoOs />
        <Fotter />
    </div>
  );
}


export default App;


import React from 'react';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import background from '../ui/back.png';
import face from '../ui/sander.png';
import { Link  } from 'react-scroll';


//import app.css
import '../index.css';

const WellSite = () => {
    return (
            <Box 
                sx={{
                    width: '100%',
                        minHeight: {
                            xs: '100vh',
                            md: '80vh',
                        },
                    zIndex: 0,
                    backgroundSize: 'cover',
                    color: 'white',
                    backgroundImage: `url(${background})`,
                }}>
                <Container 
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: { xs: 'none', md: 'center' },
                        textAlign: 'center',
                    }}>
                    <Grid container spacing={2} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                        <Grid item xs={12} md={6} mt={10}>
                            <Typography 
                                variant="h3" 
                                fontWeight="bold" 
                                gutterBottom 
                                className="custom-font"
                                sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}
                            >
                                Velkommen til Softrunner
                            </Typography>
                            <Typography 
                                variant="h6" 
                                fontWeight="bold" 
                                gutterBottom 
                                sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}
                            >
                                Vi løser dine IT problemer indenfor
                            </Typography>
                            <Typography 
                                variant="h6" 
                                fontWeight="bold" 
                                gutterBottom 
                                sx={{ 
                                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                                    marginBottom: {
                                        xs: '2rem',
                                        md: '0rem'
                                    }
                                }}>
                                        soft- og hardware
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ mt: { xs: 0, md: 10 }}}>
                            <Box
                                component="img"
                                borderRadius="20%"
                                src={face}
                                alt="icon"
                                sx={{ width: { xs: '10rem', md: '15rem' } }}
                            />
                            <Typography 
                                variant="h6" 
                                sx={{ fontWeight: 'bold', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}
                            >
                                Jeg vil sørge for at 
                            </Typography>
                            <Typography 
                                variant="h6" 
                                sx={{ fontWeight: 'bold', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}
                            >
                               dit IT problem bliver løst 
                            </Typography>
                        </Grid>
                    </Grid>
                    <Container sx={{ justifyContent: 'center', alignItems: 'center', p: 3 }}>
                        <Button
                            variant="contained"
                            size="large"
                            sx={{ borderRadius: '10px', mx: 2 }}
                            to="kontakt"
                            smooth={true}
                            duration={1000}
                        >
        <Link to="kontakt" smooth={true} duration={1000}>Giv Softrunner et kald</Link>
                        </Button>
                    </Container>
                </Container>
            </Box>

  );
};

export default WellSite;



/*
*import React from 'react';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import background from '../ui/back.png';
import face from '../ui/CEOimg.png';
import { Link } from 'react-scroll';
import '../index.css';

const WellSite = () => {
    return (
        <Box sx={{ minHeight: '100vh', color: 'white' }}>
            <Box 
                sx={{
                    width: '100%',
                    height: '100vh',
                    zIndex: 0,
                    backgroundSize: 'cover',
                    backgroundImage: `url(${background})`,
                }}>
                <Container 
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: { xs: 'none', md: 'center' },
                        textAlign: 'center',
                        pt: 8
                    }}>
                    <Grid container spacing={2} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                        <Grid item xs={12} md={6}>
                            <Typography 
                                variant="h3" 
                                color="white" 
                                fontWeight="bold" 
                                gutterBottom 
                                className="custom-font"
                                sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}
                            >
                                Velkommen til Softrunner
                            </Typography>
                            <Typography 
                                variant="h6" 
                                color="white" 
                                fontWeight="bold" 
                                gutterBottom 
                                sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}
                            >
                                Vi løser din IT problemer indenfor
                            </Typography>
                            <Typography 
                                variant="h6" 
                                color="white" 
                                fontWeight="bold" 
                                gutterBottom 
                                sx={{ 
                                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                                    marginBottom: {
                                        xs: '4rem',
                                        md: '0rem'
                                    }
                                }}
                            >
                                software og hardware
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box
                                component="img"
                                borderRadius="20%"
                                src={face}
                                alt="icon"
                                sx={{ width: "15rem" }}
                            />
                            <Typography 
                                variant="h6" 
                                color="white"
                                sx={{ fontWeight: 'bold', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}
                            >
                                Jeg vil skabe en løsning
                            </Typography>
                            <Typography 
                                variant="h6" 
                                sx={{ fontWeight: 'bold', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}
                            >
                                der passer til dine behov
                            </Typography>
                        </Grid>
                    </Grid>
                    <Container sx={{ justifyContent: 'center', alignItems: 'center', p: 3 }}>
                        <Button
                            variant="contained"
                            size="large"
                            sx={{ borderRadius: '10px', marginTop: '2rem' }}
                            component={Link}
                            to="kontakt"
                            smooth={true}
                            duration={1000}
                        >
                            Giv Softrunner et kald
                        </Button>
                    </Container>
                </Container>
            </Box>
        </Box>
    );
};

export default WellSite;


*/





 import React from 'react';
import { Box, Container, Typography, Button, Grid, TextField, IconButton } from '@mui/material';
import face2 from '../ui/sejtBilledeAFSander.png';
import {  Element  } from 'react-scroll';

const omSoftrunner = () => {

    return (
<Element name="om">
    <Box paddingY={5}
        sx={{ backgroundColor: '#f5f5f5', minHeight: '80vh', display: 'flex', justifyContent: 'center', flexDirection: 'column',
            alignItems: 'center', textAlign: 'center' }}>
          <Typography variant="h4" gutterBottom>
            Om Mig
          </Typography>
      <Grid container spacing={0} alignItems="center" justifyContent="center">
        <Grid item xs={12} md={6}>
          <Box
            component="img"
            src={face2}
            alt="Alexander"
            mt={2}
            sx={{
                width: '65%',
                transition: 'transform 0.3s',
                '&:hover': {
                    transform: 'scale(1.1)',
                    },
              borderRadius: '16px',
              boxShadow: 3,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
        <Container>
        <Typography variant="body1" paragraph align="left">
        Hej, jeg hedder Alexander. 
        </Typography>
          <Typography variant="body1" paragraph align="left">
Jeg læser cyber- og computerteknologi på Aalborg Universitet i København, og til daglig arbejder jeg som lokal IT-supporter for Lenovo her i Søborg. Min passion for IT har altid drevet mig til at hjælpe andre med deres teknologiske udfordringer.
          </Typography>
          <Typography variant="body1" paragraph align="left">
            Jeg tilbyder hjælp med en bred vifte af IT-problemer, herunder printere, netværk og andre hardware- og softwareproblemer. Uanset om du har problemer med at få din printer til at fungere, dit netværk til at køre optimalt, eller andre IT-udfordringer, er jeg klar til at træde til med en hurtig og effektiv løsning.
          </Typography>
        <Typography variant="body1" paragraph align="left">
        På universitetet fordyber jeg mig i avancerede emner inden for cyber- og computerteknologi, hvilket giver mig en solid forståelse af de nyeste teknologiske fremskridt. Jeg har arbejdet på adskillige softwareprojekter, herunder skræddersyede webløsninger, mobilapplikationer og management software, og jeg kan også hjælpe dig med at udvikle skræddersyede applikationer til dine behov.
        </Typography>
      <Typography variant="body1" paragraph align="left">
          Hvis du har et IT-problem, så tøv ikke med at kontakte mig. Mit motto er: "Hvis IT'en driller, så ring til mig – jeg er hos dig inden for 10 minutter!"
      </Typography>
        </Container>
        </Grid>
      </Grid>
    </Box>
</Element>
  );
};

export default omSoftrunner;
        




import React from 'react';
import { AppBar, Box,  Button, Toolbar, Typography } from '@mui/material';
import { Link } from 'react-scroll';
import icon from '../ui/smlIcon.png';

const HomeBar = () => {
    return (
        <AppBar position="absolute" sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
        <Toolbar>
        <Box component="img"
        src={icon}
        alt="icon"
        sx={{
            height: '3rem',
        }}>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
                  <Button color="inherit" sx={{ textDecoration: 'underline', color: 'black' }}>
                  <Link to="services" smooth={true} 
        duration={1000}>Services</Link>
              </Button>
                  <Button color="inherit" sx={{ textDecoration: 'underline', color: 'black' }}>
                  <Link to="kontakt" smooth={true} duration={1000}>Kontakt</Link>
              </Button>
              <Button color="inherit" sx={{ textDecoration: 'underline', color: 'black' }}>
                <Link to="om" smooth={true} duration={1000}>Om</Link>
              </Button>
        </Toolbar>
      </AppBar>
    )
}

export default HomeBar;
